export default {
  general: {
    username: 'Nom d\'utilisateur ',
    password: 'Mot de passe ',
    newpass: 'Nouveau mot de passe ',
    currentpass: 'Mot de passe actuel ',
    c_password: 'Confirmer votre mot de passe ',
    c_newpass: 'Confirmer votre nouveau mot de passe ',
    email: 'Adresse mail ',
    language: 'Langage ',
    subject: 'Objet ',
    message: 'Message ',
    filtername: 'Nom du Filtre ',
    placeholders: {
      username: 'Saisir votre nom d\'utilisateur',
      password: 'Saisir votre mot de passe',
      newpass: 'Saisir votre nouveau mot de passe',
      currentpass: 'Saisir votre mot de passe actuel',
      c_password: 'Confirmer votre mot de passe',
      c_newpass: 'Confirmer votre nouveau mot de passe',
      email: 'Saisir votre adresse mail',
      subject: 'Rédiger l\'objet',
      message: 'Rédiger votre message',
      filtername: 'Saisir un nom'
    },
    errors: {
      language: {
        load: 'Echec de sélection du langage.'
      }
    },
    here: 'ici',
    ok: 'Ok',
    cancel: 'Abandonner',
    on: 'oui',
    off: 'non',
    min: 'Valeur minimum ',
    max: 'Valeur maximum ',
    years: '{n} an | {n} ans',
    days: '{n} jour | {n} jours',
    type_for_more: 'Tapez pour en voir plus...'
  },
  notifications: {
    disabled: 'Les {notifications} sont bloquées. Si vous ne les avez pas bloquées, veuillez utiliser un autre navigateur.',
    notifications: 'notifications',
    title: 'Paramètres de notification',
    form: {
      email: 'Notifications par e-mail ',
      email_frequency: 'Fréquence des notifications par e-mail',
      browser: 'Notifications du navigateur ',
      browser_frequency: 'Fréquence des notifications du navigateur',
      frequency: 'immédiatement | une fois par heure | une fois toutes les {n} heures',
      help: {
        email: 'Vous recevrez des notifications sur des joueurs par e-mail.',
        browser: 'Vous recevrez des notifications sur des joueurs directement sur votre navigateur.',
        frequency: 'Il est recommandé de sélectionner \'immédiatement\' afin d\'éviter de manquer des joueurs.',
        blocked: 'Vous avez bloqué les notifications ou votre navigateur ne les prend pas en charge.'
      }
    }
  },
  header: {
    menu: {
      home: 'Accueil',
      account: 'Compte',
      signin: 'Connexion',
      register: 'S\'inscrire',
      inbox: 'Inbox',
      blog: 'Blog',
      contact: 'Contactez-nous',
      logout: 'Déconnexion'
    }
  },
  footer: {
    menu: {
      privacy: 'Confidentialité (Anglais)',
      terms: 'Termes (Anglais)'
    }
  },
  login: {
    title: 'Connexion',
    signin: 'Connexion',
    register: 'S\'inscrire',
    forgot: 'Mot de passe oublié ?',
    form: {
      submit: 'Connexion',
      remember: 'Se souvenir de moi'
    },
    info: {
      what: 'A quoi sert Mercattrick ?',
      purpose: 'Le but de Mercattrick est de :',
      purpose1: 'donner aux <strong>managers</strong> de Hattrick qui <strong>vendent</strong> des joueurs une meilleure vitrine pour leurs joueurs, afin d\'en tirer le meilleur prix de vente ;',
      purpose2: 'permettre aux <strong>managers</strong> de Hattrick qui <strong>sont intéressés pour acheter</strong> un certain type de joueur, de recevoir des notifications lorsque des joueurs intéressants sont ajoutés sur le marché des transferts ;',
      how: 'Comment cela marche-t-il ?',
      buyers: 'Les acheteurs',
      step1: 'se connectent et définissent un ensemble de caractéristiques minimales (filtres) concernant les joueurs pour lesquels ils veulent recevoir des notifications lorsqu\'ils sont en vente.',
      sellers: 'Les vendeurs',
      step2: 'mettent un joueur en vente sur Hattrick, puis se connectent pour " promouvoir " ce joueur.',
      promoting: 'Promouvoir',
      step3: 'un joueur signifie que tous les " acheteurs " intéressés par ce type de joueur seront notifiés lorsque le joueur sera mis en vente.'
    }
  },
  register: {
    title: 'S\'inscrire',
    form: {
      registered: 'Votre compte a été créé. Cliquer {here} pour continuer.',
      submit: 'S\'inscrire',
      agreement: 'En cliquant sur @:register.form.submit vous signifiez votre accord avec les {terms} et la {privacy} et de recevoir occasionnellement des e-mails liés à votre compte.',
      tos: 'Conditions d\'utilisation',
      privacy: 'Déclaraion de confidentialité',
      help: {
        username: 'Cela n\'est pas votre nom d\'utilisateur sur Hattrick.',
        password: 'Veuillez saisir un minimum de 6 caractères. Le mot de passe est sensible à la casse.',
        email: 'Nous utiliserons cette adresse mail afin de vous envoyer des notifications concernant de nouveaux joueurs ajoutés sur le marché des transferts.'
      }
    }
  },
  recover: {
    title: 'Récupérer votre mot de passe',
    form: {
      recovered: 'Un e-mail avec un lien de ré-initialisation a été envoyé à votre adresse mail.',
      submit: 'Récupérer',
      help: {
        email: 'Cette adresse doit correspondre à l\'adresse mail associée à votre compte.'
      }
    }
  },
  reset: {
    title: 'Ré-initialiser votre mot de passe',
    form: {
      submit: 'Ré-initialiser',
      help: {
        email: 'Cette adresse doit correspondre à l\'adresse mail associée à votre compte.',
        password: 'Veuillez saisir un minimum de 6 caractères. Le mot de passe est sensible à la casse.'
      }
    }
  },
  contact: {
    title: 'Contactez-nous',
    form: {
      success: 'Le message a été envoyé avec succès.',
      submit: 'Envoyer',
      help: {
        email: 'Nous utiliserons cette adresse mail pour vous répondre.'
      }
    }
  },
  sync: {
    users: {
      syncing: 'Synchroniser les détails de l\'utilisateur...',
      fail: 'La synchronisation de l\'utilisateur a échoué.',
      retry: 'Essayer à nouveau',
      authorize: 'Vous devez donner l\'autorisation à ce produit CHPP. Veuillez cliquer {here} pour ouvrir le site de Hattrick.',
      authorizing: 'Demande d\'autorisation initialisée. Vous serez redirigés sur <strong>hattrick.org</strong> d\'ici peu...',
      finalize: 'Finalisation de la emande d\'autorisation...'
    },
    system: {
      syncing: 'Synchroniser les détails du système...',
      fail: 'La synchronisation du système a échoué. {retry} ou {skip} !',
      retry: 'Essayer à nouveau',
      skip: 'Ignorer'
    }
  },
  account: {
    account: {
      title: 'Paramètres du compte',
      form: {
        success: 'Le compte a été mis à jour avec succès.',
        submit: 'Sauvegarder',
        help: {
          username: 'Cela n\'est pas votre nom d\'utilisateur sur Hattrick.',
          email: 'Nous utiliserons cette adresse mail pour vous envoyer des notifications concernant de nouveaux joueurs.'
        }
      }
    },
    login: {
      title: 'Paramètres de connexion',
      form: {
        success: 'Le mot de passe a été mis à jour avec succès.',
        submit: 'Sauvegarder',
        help: {
          passwords: 'Veuillez saisir votre mot de passe actuel, votre nouveau mot de passe et le mot de passe de confirmation.'
        }
      }
    },
    privacy: {
      title: 'Paramètres de confidentialité',
      form: {
        submit: 'Supprimer le compte',
        delete: 'Supprimer',
        help: {
          warning: 'Cette action est définitive. Toutes vos données seront effacées.',
          confirmation: 'Veuillez confirmer s\'il vous plaît que vous souhaitez supprimer définitivement votre compte.'
        }
      }
    }
  },
  filter: {
    title: 'Appuyez pour ajouter/retirer un filtre',
    form: {
      submit: 'Sauvegarder',
      set: 'Définir',
      remove: 'Supprimer',
      help: {
        name: 'Donnez un nom à votre filtre pour mieux l\'identifier.'
      }
    },
    tooltip: {
      save: 'Vous devez définir au moins un critère de filtre !'
    },
    item: {
      tooltip: 'Cliquez sur \'@:filter.item.show_players\' pour afficher la liste.',
      show_players: 'Montrer les joueurs',
      hide_players: 'Cacher les joueurs',
      players: 'joueur trouvé | joueurs trouvés',
      form: {
        processing: 'en cours',
        fetch: 'Rechercher des joueurs sur HT',
        edit: 'Editer',
        delete: 'Supprimer',
        help: {
          confirm: 'Êtes-vous sûr(e) de vouloir supprimer ce filtre ?'
        }
      }
    }
  },
  home: {
    myplayers: {
      title: 'Mes transferts',
      loading: 'Chargement des joueurs...',
      none: 'Vous n\'avez actuellement aucun joueur en vente.',
      promoted: 'promu',
      late: 'trop tard pour le promouvoir',
      form: {
        reload: 'Recharger',
        promote: 'Promouvoir'
      },
      tooltip: 'Promouvez vos joueurs pour que d\'autres managers puissent les voir.'
    },
    bookmarks: {
      title: 'Signets',
      loading: 'Chargement des joueurs...',
      none: 'Vous n\'avez actuellement aucun joueur en signet.',
      form: {
        reload: 'Recharger',
        unbookmark: 'Retirer le signet',
        help: {
          confirm: 'Êtes-vous sûr(e) de vouloir supprimer ce signet ?'
        }
      },
      tooltip: 'Vous recevrez des notifications concernant ces joueurs lorsque l\'échéance de transfert sera proche.'
    },
    filters: {
      title: 'Mes filtres',
      loading: 'Chargement des filtres...',
      none: 'Vous n\'avez aucun filtre. Vous devriez en créer.',
      form: {
        reload: 'Recharger',
        add: 'Ajouter filtre',
        bookmark: 'Marquer',
        hide: 'Cacher',
        help: {
          confirm: 'Êtes-vous sûr(e) de vouloir cacher ce joueur ?'
        }
      },
      tooltip: 'Mettre en signet des joueurs intéressants dans ces listes.'
    }
  },

  player: {
    attributes: {
      name: 'Nom',
      age: 'Âge',
      form: 'Forme',
      experience: 'Expérience',
      leadership: 'Tempérament de chef',
      stamina_skill: 'Endurance',
      keeper_skill: 'Gardien',
      playmaker_skill: 'Construction',
      scorer_skill: 'Buteur',
      passing_skill: 'Passe',
      winger_skill: 'Ailier',
      defender_skill: 'Défense',
      set_pieces_skill: 'Coup franc',
      agreeability: 'Popularité',
      aggressiveness: 'Agressivité',
      honesty: 'Honnêteté',
      htms: 'HTMS',
      htms28: 'HTMS28',
      tsi: 'TSI',
      salary: 'Salaire',
      price: 'Prix',
      asking_price: 'Mise à prix',
      highest_bid: 'Plus haute enchère',
      native_country: 'Pays d\'origine',
      native_country_id: 'Pays d\'origine',
      NT_player: 'Joueur en équipe nationale',
      specialties: 'Spécialités',
      specialty: 'Spécialité'
    },
    redcard: 'Carton rouge',
    yellowcard: 'Carton jaune',
    bruised: 'Pansement',
    injured: 'Blessé',
    injury: '{n} semaine | {n} semaines',
    fullage: '{years} ans et {days} jours',
    deadline: 'Echéance',
    htms: 'HTMS/HTMS28',
    more: 'Montrer plus',
    less: 'Montrer moins',
    levels: {
      skill: [
        'inexistant',
        'catastrophique',
        'mauvais',
        'médiocre',
        'faible',
        'inadéquat',
        'passable',
        'honorable',
        'excellent',
        'formidable',
        'impressionnant',
        'brillant',
        'inoubliable',
        'légendaire',
        'surnaturel',
        'titanesque',
        'extraterrestre',
        'mythique',
        'magique',
        'utopique',
        'divin',
        'divin (+1)',
        'divin (+2)',
        'divin (+3)',
        'divin (+4)',
        'divin (+5)'
      ],
      agreeability: [
        'type désagréable',
        'personnage controversé',
        'gars aimable',
        'gars sympathique',
        'gars populaire',
        'type adulé par ses coéquipiers'
      ],
      aggressiveness: [
        'tranquille',
        'calme',
        'équilibré',
        'caractériel',
        'impulsif',
        'instable'
      ],
      honesty: [
        'fourbe',
        'malhonnête',
        'honnête',
        'sincère',
        'intègre',
        'vertueux'
      ],
      specialty: [
        'aucune',
        'technique',
        'rapide',
        'costaud',
        'imprévisible',
        'joueur de tête',
        'résistant',
        '',
        'chef d\'orchestre'
      ],
      national_team: [
        'jamais',
        'actuellement non',
        'actuellement oui'
      ],
      any: 'tout/aucun'
    },
    tooltips: {
      highest_bid: 'La plus haute enchère peut être obsolète !'
    }
  },
  countries: {
    1: 'Suède',
    2: 'Angleterre',
    3: 'Allemagne',
    4: 'Italie',
    5: 'France',
    6: 'Mexique',
    7: 'Argentine',
    8: 'USA',
    9: 'Norvège',
    10: 'Danemark',
    11: 'Finlande',
    12: 'Pays-Bas',
    13: 'Océanie',
    14: 'Canada',
    15: 'Ecosse',
    16: 'Irlande',
    17: 'Chili',
    18: 'Colombie',
    19: 'Uruguay',
    20: 'Venezuela',
    21: 'Pérou',
    22: 'Brésil',
    23: 'Portugal',
    24: 'Afrique du Sud',
    25: 'Japon',
    26: 'Pologne',
    27: 'Inde',
    28: 'Chine',
    29: 'Corée du Sud',
    30: 'Thaïlande',
    31: 'Turquie',
    32: 'Egypte',
    33: 'Autriche',
    34: 'Russie',
    35: 'Espagne',
    36: 'Roumanie',
    37: 'Islande',
    38: 'Belgique',
    39: 'Malaisie',
    40: 'Suisse',
    41: 'Singapour',
    42: 'Croatie',
    43: 'Serbie',
    44: 'Hongrie',
    45: 'Grèce',
    46: 'République tchèque',
    47: 'Estonie',
    48: 'Lettonie',
    49: 'Indonésie',
    50: 'Philippines',
    51: 'Israël',
    52: 'Taïwan',
    53: 'Hong Kong',
    55: 'Bulgarie',
    56: 'Pays de Galles',
    57: 'Slovénie',
    61: 'Lituanie',
    62: 'Ukraine',
    63: 'Bosnie-Herzégovine',
    64: 'Pakistan',
    65: 'Vietnam',
    66: 'Slovaquie',
    67: 'Paraguay',
    68: 'Equateur',
    69: 'Bolivie',
    70: 'Nigeria',
    71: 'Îles Féroé',
    72: 'Maroc',
    75: 'Arabie Saoudite',
    76: 'Tunisie',
    77: 'Costa Rica',
    78: 'Emirats Arabes Unis',
    79: 'Luxembourg',
    80: 'Iran',
    82: 'Chypre',
    83: 'République Dominicaine',
    86: 'Sénégal',
    87: 'Bélarus',
    88: 'Irlande du Nord',
    89: 'Jamaïque',
    90: 'Kenya',
    91: 'Panama',
    92: 'Macédoine',
    93: 'Cuba',
    94: 'Albanie',
    95: 'Honduras',
    96: 'Salvador',
    97: 'Malte',
    98: 'Kirghizistan',
    99: 'Moldavie',
    100: 'Géorgie',
    101: 'Andorre',
    102: 'Guatemala',
    103: 'Jordanie',
    104: 'Arménie',
    105: 'Trinité & Tobago',
    121: 'Nicaragua',
    122: 'Kazakhstan',
    123: 'Surinam',
    125: 'Liechtenstein',
    126: 'Algérie',
    127: 'Mongolie',
    128: 'Liban',
    129: 'Bahreïn',
    130: 'Barbade',
    131: 'Cap-Vert',
    132: 'Côte d\'Ivoire',
    133: 'Azerbaïdjan',
    134: 'Koweït',
    135: 'Irak',
    136: 'Monténégro',
    137: 'Angola',
    138: 'Bangladesh',
    139: 'Yémen',
    140: 'Oman',
    142: 'Mozambique',
    143: 'Brunei',
    144: 'Ghana',
    145: 'Cambodge',
    147: 'Bénin',
    148: 'Syrie',
    149: 'Qatar',
    150: 'Tanzanie',
    153: 'Ouganda',
    154: 'Maldives',
    163: 'Ouzbékistan',
    165: 'Cameroun',
    166: 'Palestine',
    175: 'Sri Lanka',
    177: 'Sao Tome-et-Principe',
    178: 'Curaçao',
    179: 'Guam',
    180: 'Comores',
    181: 'RD Congo',
    182: 'Ethiopie',
    183: 'Madagascar',
    184: 'Saint-Vincent-et-les-Grenadines',
    185: 'Botswana',
    186: 'Belize',
    187: 'Zambie',
    188: 'Haïti',
    189: 'Myanmar',
    190: 'Porto Rico',
    191: 'San Marino',
    192: 'Népal',
    193: 'Burkina Faso',
    194: 'Grenade',
    196: 'Tahiti',
    197: 'Guyane'
  },

  validation: {
    attributes: {},
    messages: {
      alpha_num: 'Ce champ ne peut contenir que des caractères alpha-numériques.',
      alpha_dash: 'Ce champ ne peut contenir que des caractères alpha-numériques, des underscores ou des tirets.',
      confirmed: 'Le champ de confirmation ne correspond pas.',
      email: 'Ce champ doit contenir une adresse e-mail valide.',
      max: 'Ce champ contient trop de caractères.',
      min: 'Ce champ ne contient pas assez de caractères.',
      required: 'Ce champ est requis.'
    }
  },

  api: {
    validation: {
      string: {
        subject: 'L\'objet est requis.',
        body: 'Le contenu est requis.',
        username: 'Le nom d\'utilisateur est requis.',
        password: 'Le mot de passe est requis.',
        locale: 'Le langage est requis.',
        name: 'Le nom est requis.',
        token: 'Le token est requis.'
      },
      integer: {
        native_country_id: 'L\'ID du pays doit être un nombre.',
        htms_min: 'La valeur minimum du HTMS doit être un nombre.',
        htms_max: 'La valeur maximum du HTMS doit être un nombre.',
        htms28_min: 'La valeur minimum du HTMS28 doit être un nombre.',
        htms28_max: 'La valeur maximum du HTMS28 doit être un nombre.',
        tsi_min: 'La valeur minimum du TSI doit être un nombre.',
        tsi_max: 'La valeur maximum du TSI doit être un nombre.'
      },
      numeric: {
        age_min: 'L\'âge minimum doit être un nombre.',
        age_max: 'L\'âge maximum doit être un nombre.',
        salary_min: 'Le salaire minimum doit être un nombre.',
        salary_max: 'Le salaire maximum doit être un nombre.',
        price_min: 'Le prix minimum doit être un nombre.',
        price_max: 'Le prix maximum doit être un nombre.'
      },
      required: {
        subject: 'L\'objet est requis.',
        body: 'Le contenu est requis.',
        username: 'Le nom d\'utilisateur est requis.',
        password: 'Le mot de passe est requis.',
        current_password: 'Le mot de passe actuel est requis.',
        new_password: 'Le nouveau mot de passe est requis.',
        email: 'L\'adresse e-mail est requise.',
        token: 'Le token est requis.',
        endpoint: 'Le paramètre \'endpoint\' est requis.',
        url: 'Le paramètre\'URL\' est requis.',
        oauth_token: 'Le paramètre \'oauth_token\' est requis.',
        oauth_verifier: 'Le paramètre \'oauth_verifier\' est requis.'
      },
      max: {
        age_min: 'L\'âge minimum doit être inférieur à 100.',
        age_max: 'L\'âge maximum doit être inférieur à 100.',
        htms_min: 'Le HTMS minimum doit être inférieur à 12 000.',
        htms_max: 'Le HTMS maximum doit être inférieur à 12 000.',
        htms28_min: 'Le HTMS28 minimum doit être inférieur à 12 000.',
        htms28_max: 'Le HTMS28 maximum doit être inférieur à 12 000.',
        tsi_min: 'Le TSI minimum doit être inférieur à 16 000 000.',
        tsi_max: 'Le TSI maximum doit être inférieur à 16 000 000.',
        subject: 'L\'objet contient trop de caractères.',
        username: 'Le nom d\'utilisateur contient trop de caractères.',
        email: 'L\'adresse e-mail contient trop de caractères.',
        locale: 'Le langage contient trop de caractères.',
        name: 'Le nom contient trop de caractères.',
        token: 'Le token contient trop de caractères.',
        NT_player: 'La valeur pour le joueur en équipe nationale doit être à 2 maximum (\'@:player.levels.national_team[2]\').',

        string: {
          subject: '@:api.validation.max.subject',
          username: '@:api.validation.max.username',
          email: '@:api.validation.max.email',
          locale: '@:api.validation.max.locale',
          name: '@:api.validation.max.name',
          token: '@:api.validation.max.token',
          NT_player: '@:api.validation.max.NT_player'
        },

        numeric: {
          age_min: '@:api.validation.max.age_min',
          age_max: '@:api.validation.max.age_max',
          htms_min: '@:api.validation.max.htms_min',
          htms_max: '@:api.validation.max.htms_max',
          htms28_min: '@:api.validation.max.htms28_min',
          htms28_max: '@:api.validation.max.htms28_max',
          tsi_min: '@:api.validation.max.tsi_min',
          tsi_max: '@:api.validation.max.tsi_max',
          NT_player: '@:api.validation.max.NT_player'
        }
      },
      min: {
        password: 'Le mot de passe contient trop peu de caractères.',
        age_min: 'L\'âge minimum doit être supérieur à 17.',
        age_max: 'L\'âge maximum doit être supérieur à 17.',
        native_country_id: 'L\'ID du pays doit être un entier supérieur à 0.',
        htms_min: 'Le HTMS minimum doit être un entier positif.',
        htms_max: 'Le HTMS maximum doit être un entier positif.',
        htms28_min: 'Le HTMS28 minimum doit être supérieur à -12 000.',
        htms28_max: 'Le HTMS28 maximum doit être supérieur à -12 000.',
        tsi_min: 'Le TSI minimum doit être un entier positif.',
        tsi_max: 'Le TSI maximum doit être un entier positif.',
        salary_min: 'Le salaire minimum doit être un entier positif.',
        salary_max: 'Le salaire maximum doit être un entier positif.',
        price_min: 'Le prix minimum doit être un entier positif.',
        price_max: 'Le prix maximum doit être un entier positif.',
        NT_player: 'La valeur pour le joueur en équipe nationale doit être à 0 minimum (\'@:player.levels.national_team[0]\').',

        string: {
          password: '@:api.validation.min.password',
          age_min: '@:api.validation.min.age_min',
          age_max: '@:api.validation.min.age_max',
          native_country_id: '@:api.validation.min.native_country_id',
          htms_min: '@:api.validation.min.htms_min',
          htms_max: '@:api.validation.min.htms_max',
          htms28_min: '@:api.validation.min.htms28_min',
          htms28_max: '@:api.validation.min.htms28_max',
          tsi_min: '@:api.validation.min.tsi_min',
          tsi_max: '@:api.validation.min.tsi_max',
          salary_min: '@:api.validation.min.salary_min',
          salary_max: '@:api.validation.min.salary_max',
          price_min: '@:api.validation.min.price_min',
          price_max: '@:api.validation.min.price_max',
          NT_player: '@:api.validation.min.NT_player'
        },

        numeric: {
          age_min: '@:api.validation.min.age_min',
          age_max: '@:api.validation.min.age_max',
          native_country_id: '@:api.validation.min.native_country_id',
          htms_min: '@:api.validation.min.htms_min',
          htms_max: '@:api.validation.min.htms_max',
          htms28_min: '@:api.validation.min.htms28_min',
          htms28_max: '@:api.validation.min.htms28_max',
          tsi_min: '@:api.validation.min.tsi_min',
          tsi_max: '@:api.validation.min.tsi_max',
          salary_min: '@:api.validation.min.salary_min',
          salary_max: '@:api.validation.min.salary_max',
          price_min: '@:api.validation.min.price_min',
          price_max: '@:api.validation.min.price_max',
          NT_player: '@:api.validation.min.NT_player'
        }
      },
      email: {
        email: 'Le champ de l\'adresse e-mail doit contenir une adresse e-mail valide.'
      },
      url: {
        url: 'Le paramètre \'URL\' doit contenir une URL valide.'
      },
      in: {
        endpoint: 'Le paramètre \'endpoint\' doit être valide.',
        form_min: 'La forme minimum doit être comprise entre 1 (\'@:player.levels.skill[1]\') et 8 (\'@:player.levels.skill[8]\').',
        form_max: 'La forme maximum doit être comprise entre 1 (\'@:player.levels.skill[1]\') et 8 (\'@:player.levels.skill[8]\').',
        agreeability_min: 'La popularité minimum doit être comprise entre 0 (\'@:player.levels.agreeability[0]\') et 20 (\'@:player.levels.agreeability[5]\').',
        agreeability_max: 'La popularité maximum doit être comprise entre 0 (\'@:player.levels.agreeability[0]\') et 20 (\'@:player.levels.agreeability[5]\').',
        aggressiveness_min: 'L\'agressivité minimum doit être comprise entre 0 (\'@:player.levels.aggressiveness[0]\') et 20 (\'@:player.levels.aggressiveness[5]\').',
        aggressiveness_max: 'L\'agressivité maximum doit être comprise entre 0 (\'@:player.levels.aggressiveness[0]\') et 20 (\'@:player.levels.aggressiveness[5]\').',
        honesty_min: 'L\'honnêteté minimum doit être comprise entre 0 (\'@:player.levels.honesty[0]\') et 20 (\'@:player.levels.honesty[5]\').',
        honesty_max: 'L\'honnêteté maximum doit être comprise entre 0 (\'@:player.levels.honesty[0]\') et 20 (\'@:player.levels.honesty[5]\').',
        experience_min: 'L\'expérience minimum doit être comprise entre 0 (\'@:player.levels.skill[0]\') et 25 (\'@:player.levels.skill[25]\').',
        experience_max: 'L\'expérience maximum doit être comprise entre 0 (\'@:player.levels.skill[0]\') et 25 (\'@:player.levels.skill[25]\').',
        leadership_min: 'Le tempérament de chef minimum doit être compris entre 1 (\'@:player.levels.skill[1]\') et 20 (\'@:player.levels.skill[8]\').',
        leadership_max: 'Le tempérament de chef maximum doit être compris entre 1 (\'@:player.levels.skill[1]\') et 20 (\'@:player.levels.skill[8]\').',
        stamina_skill_min: 'L\'endurance minimum doit être comprise entre 0 (\'@:player.levels.skill[0]\') et 20 (\'@:player.levels.skill[9]\').',
        stamina_skill_max: 'L\'endurance maximum doit être comprise entre 0 (\'@:player.levels.skill[0]\') et 20 (\'@:player.levels.skill[9]\').',
        keeper_skill_min: 'La caractéristique gardien minimum doit être comprise entre 0 (\'@:player.levels.skill[0]\') et 25 (\'@:player.levels.skill[25]\').',
        keeper_skill_max: 'La caractéristique gardien maximum doit être comprise entre 0 (\'@:player.levels.skill[0]\') et 25 (\'@:player.levels.skill[25]\').',
        playmaker_skill_min: 'La caractéristique construction minimum doit être comprise entre 0 (\'@:player.levels.skill[0]\') et 25 (\'@:player.levels.skill[25]\').',
        playmaker_skill_max: 'La caractéristique construction maximum doit être comprise entre 0 (\'@:player.levels.skill[0]\') et 25 (\'@:player.levels.skill[25]\').',
        scorer_skill_min: 'La caractéristique buteur minimum doit être comprise entre 0 (\'@:player.levels.skill[0]\') et 25 (\'@:player.levels.skill[25]\').',
        scorer_skill_max: 'La caractéristique buteur maximum doit être comprise entre 0 (\'@:player.levels.skill[0]\') et 25 (\'@:player.levels.skill[25]\').',
        passing_skill_min: 'La caractéristique passe minimum doit être comprise entre 0 (\'@:player.levels.skill[0]\') et 25 (\'@:player.levels.skill[25]\').',
        passing_skill_max: 'La caractéristique passe maximum doit être comprise entre 0 (\'@:player.levels.skill[0]\') et 25 (\'@:player.levels.skill[25]\').',
        winger_skill_min: 'La caractéristique ailier minimum doit être comprise entre 0 (\'@:player.levels.skill[0]\') et 25 (\'@:player.levels.skill[25]\').',
        winger_skill_max: 'La caractéristique ailier maximum doit être comprise entre 0 (\'@:player.levels.skill[0]\') et 25 (\'@:player.levels.skill[25]\').',
        defender_skill_min: 'La caractéristique défense minimum doit être comprise entre 0 (\'@:player.levels.skill[0]\') et 25 (\'@:player.levels.skill[25]\').',
        defender_skill_max: 'La caractéristique défense maximum doit être comprise entre 0 (\'@:player.levels.skill[0]\') et 25 (\'@:player.levels.skill[25]\').',
        set_pieces_skill_min: 'La caractéristique coup franc minimum doit être comprise entre 0 (\'@:player.levels.skill[0]\') et 25 (\'@:player.levels.skill[25]\').',
        set_pieces_skill_max: 'La caractéristique coup franc maximum doit être comprise entre 0 (\'@:player.levels.skill[0]\') et 25 (\'@:player.levels.skill[25]\').'
      },
      exists: {
        user_id: 'L\'utilisateur n\'existe plus.'
      },
      boolean: {
        promoted: 'Le paraètre \'promoted\' doit être à vrai ou faux.',
        bookmarked: 'Le paraètre \'bookmarked\' doit être à vrai ou faux.',
        ignored: 'Le paraètre \'ignored\' doit être à vrai ou faux.',
        notifications_email: 'Le paraètre \'notifications_email\' doit être à vrai ou faux.',
        notifications_desktop: 'Le paraètre \'notifications_desktop\' doit être à faux.'
      },
      array: {
        specialties: 'Les spécialités doivent contenir un ensemble de valeurs.'
      }
    },
    unknown: {
      general: 'Une erreur inattendue est survenue. Nous vous suggérons de rafraîchir la page.'
    },
    network: {
      general: 'Vous rencontrez des problèmes de connexion réseau.',
      maintenance: 'Maintenance système en cours: veuillez réessayer dans quelques minutes.'
    },
    denied: {
      access: 'Accès refusé.'
    },
    expired: {
      token: 'Le Token d\'authentication a expiré.',
      session: 'L\'utilisateur n\'est pas autentifié.'
    },
    failed: {
      registration: 'Impossible de terminer l\'inscription.',
      user_details: 'Impossible de charger les détails de l\'utilisateur.',
      player_details: 'Impossible de charger les détails du joueur.',
      filter_fetch: 'Impossible de trouver de nouveaux joueurs.',
      password_update: 'Le mot de passe n\'a pas été mis à jour.',
      chpp_connection: 'Impossible d\'initier la connexion CHPP.',
      chpp_authorization: 'Impossible de démarrer l\'autorisation CHPP.'
    },
    invalid: {
      credentials: 'Références invalides.',
      token: 'Le Token d\'authentification est invalide.',
      request: 'Requête invalide.'
    },
    limit: {
      filters: 'Vous avez atteint la limite de filtres que vous pouvez créer.'
    },
    missing: {
      token: 'Le Token d\'authentification est manquant.'
    },
    conflict: {
      username: 'Nom d\'utilisateur déjà utilisé.',
      username_password: 'Nom d\'utilisateur ou e-mail déjà utilisés.'
    }
  }
}
